<template>
  <div>
    <b-form>
      <validation-observer
        ref="elementForm"
        #default="{ invalid }">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="d-none d-sm-inline">{{
                $t(consultResourcesElementName + '.section.orders')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row class="mb-1 mt-1">
              <b-button
                :disabled="
                  disabled || disableActionsFollow(followType, finishedConsults)
                "
                class="mb-1 mt-1 mr-5"
                variant="primary"
                @click="addMedicalLaboratory()">
                {{ $t('actions.add_medical_laboratory') }}
              </b-button>

              <b-button
                :disabled="
                  disabled || disableActionsFollow(followType, finishedConsults)
                "
                class="mb-1 mt-1 mr-5"
                variant="primary"
                @click="addVaccine()">
                {{ $t('actions.add_vaccine') }}
              </b-button>

              <b-button
                :disabled="
                  disabled || disableActionsFollow(followType, finishedConsults)
                "
                class="mb-1 mt-1"
                variant="primary"
                @click="addMedicalConsult()">
                {{ $t('actions.add_medical_consult') }}
              </b-button>
            </b-row>

            <b-row>
              <b-table
                class="position-relative"
                :items="internal_orders"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                :empty-text="
                  $t(consultResourcesElementName + '.search.not_found')
                ">
                <!-- Column: actions -->
                <template #cell(actions)="data">
                  <b-col>
                    <b-button
                      v-if="data.item.status"
                      :id="get_edit_button_id(data.item.id)"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      variant="primary"
                      :disabled="disabled"
                      @click="edit_order(data.item)">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      v-if="data.item.status"
                      :id="get_delete_button_id(data.item.id)"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      variant="primary"
                      :disabled="disabled"
                      @click="delete_order(data.item)">
                      <feather-icon icon="DeleteIcon" />
                    </b-button>

                    <b-tooltip
                      v-if="data.item.status"
                      :target="get_edit_button_id(data.item.id)"
                      triggers="hover">
                      <span class="align-middle ml-50">{{
                        $t('actions.edit')
                      }}</span>
                    </b-tooltip>
                    <b-tooltip
                      v-if="data.item.status"
                      :target="get_delete_button_id(data.item.id)"
                      triggers="hover">
                      <span class="align-middle ml-50">{{
                        $t('actions.delete')
                      }}</span>
                    </b-tooltip>
                  </b-col>
                </template>
              </b-table>
            </b-row>
          </b-card-body>
        </b-card>
      </validation-observer>
    </b-form>
    <div v-if="!disabled">
      <b-modal
        id="modal-medical-consult"
        size="lg"
        hide-footer
        title="Orden médica de consulta">
        <template>
          <medical-consult
            :consult-data="orderData"
            :finished-consults="finishedConsults"
            :case-tracking-type="caseTrackingType"
            :medical-consult-services-list="medicalConsultServicesList"
            :follow-date="followDate"
            :requires-follow="requiresFollow"
            :follow-type="followType" />
        </template>
      </b-modal>
    </div>
    <div v-if="!disabled">
      <b-modal
        id="modal-clinical-laboratory"
        size="lg"
        hide-footer
        :title="
          $t(consultResourcesElementName + '.object.clinical_laboratory')
        ">
        <template>
          <clinical-laboratory
            v-if="clinicalLaboratoryServicesList"
            :order-data="orderData"
            :destination-orders-list="destinationOrdersList"
            :clinical-laboratory-city-list="clinicalLaboratoryCityList"
            :clinical-laboratory-department-list="
              clinicalLaboratoryDepartmentList
            "
            :clinical-headquarter-list="clinicalHeadquarterList"
            :clinical-laboratory-list="clinicalLaboratoryList"
            :clinical-laboratory-services-list="clinicalLaboratoryServicesList"
            :required-headquarter="requiredHeadquarter" />
        </template>
      </b-modal>
    </div>
    <div v-if="!disabled">
      <b-modal
        id="modal-vaccines"
        size="lg"
        hide-footer
        :title="$t(consultResourcesElementName + '.object.vaccine')">
        <template>
          <vaccines
            v-if="vaccineServicesList"
            :order-data="orderData"
            :destination-orders-list="destinationOrdersList"
            :vaccine-city-list="vaccineCityList"
            :vaccine-department-list="vaccineDepartmentList"
            :vaccine-headquarter-list="vaccineHeadquarterList"
            :vaccine-laboratory-list="vaccineLaboratoryList"
            :vaccine-services-list="vaccineServicesList"
            :required-vaccine-headquarter="requiredVaccineHeadquarter" />
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BModal,
  BTable,
  BTooltip,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BForm,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import MedicalConsult from '@/views/meet/local/orders/MedicalConsult.vue'
import ClinicalLaboratory from './orders/ClinicalLaboratory.vue'
import Vaccines from './orders/Vaccines.vue'

export default {
  components: {
    MedicalConsult,
    Vaccines,
    BModal,
    ClinicalLaboratory,
    BTable,
    BTooltip,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BForm,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    destinationOrdersList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryDepartmentList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryCityList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    clinicalHeadquarterList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryServicesList: {
      type: Array,
      required: true,
    },
    vaccineDepartmentList: {
      type: Array,
      required: true,
    },
    vaccineCityList: {
      type: Array,
      required: true,
    },
    vaccineLaboratoryList: {
      type: Array,
      required: true,
    },
    vaccineHeadquarterList: {
      type: Array,
      required: true,
    },
    vaccineServicesList: {
      type: Array,
      required: true,
    },
    serviceTypeList: {
      type: Array,
      required: true,
    },
    caseTrackingType: {
      type: Array,
      required: true,
    },
    medicalConsultServicesList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    caseType: {
      type: String,
      required: true,
    },
    booleanList: {
      type: Array,
      required: true,
    },
    finishedConsults: {
      type: String,
      required: true,
    },
    followType: {
      type: String,
      required: true,
    },
    requiredHeadquarter: {
      type: Boolean,
      required: true,
    },
    requiredVaccineHeadquarter: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      orderData: {},
      tableColumns: [],
      all_internal_orders: [],
      userData: {},
      followDate: '',
      proposed_date: '',
      requiresFollow: false,
    }
  },
  computed: {
    internal_orders() {
      return this.all_internal_orders.filter((element) => element.status == 1)
    },
  },
  watch: {
    elementData(newValue) {
      this.all_internal_orders = [...newValue.orders]
      this.proposedDate(newValue)
      this.followDate = this.proposed_date
    },
  },
  beforeDestroy() {
    this.$root.$off('save-order')
  },
  mounted() {
    const _self = this
    this.userData = JSON.parse(localStorage.getItem('userData'))
    _self.all_internal_orders = [..._self.elementData.orders]
    _self.proposedDate()
    this.followDate = this.proposed_date

    this.tableColumns = [
      {
        key: 'service_type.value',
        label: this.$t(
          `${this.consultResourcesElementName}.object.service_type`
        ),
        sortable: true,
      },
      {
        key: 'request_destination.value',
        label: this.$t(
          `${this.consultResourcesElementName}.object.request_destination`
        ),
        sortable: true,
      },
      {
        key: 'added_by',
        label: this.$t(`${this.consultResourcesElementName}.object.added_by`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.consultResourcesElementName}.object.actions`),
      },
    ]
    this.$root.$on('save-order', (medicineData) => {
      console.log('Orden recibida:', medicineData);
      const index = _self.elementData.orders.findIndex(
        (element) => element.id == medicineData.id
      )
      if (index < 0) {
        _self.elementData.orders.push(medicineData)
        _self.all_internal_orders.push(medicineData)
      } else {
        _self.elementData.orders[index] = medicineData;
      }
    })
  },
  methods: {
    get_delete_button_id(key) {
      return `delete_button${key}`
    },
    get_edit_button_id(key) {
      return `edit_button${key}`
    },
    disableActionsFollow(currentConsult, lastConsult) {
      return lastConsult === 'INITIAL_VALORATION'
        ? false
        : lastConsult !== currentConsult
    },
    edit_order(item) {
      this.orderData = item
      this.orderData.proposed_date = item.proposed_date || this.proposed_date;
      switch (item.service_type.key) {
        case 'FOLLOWUPS':
          this.$root.$emit('bv::show::modal', 'modal-medical-consult')
          break
        case 'LABORATORIES':
          this.$root.$emit('bv::show::modal', 'modal-clinical-laboratory')
          break
        case 'VACCINATION':
          this.$root.$emit('bv::show::modal', 'modal-vaccines')
          break
      }
    },
    delete_order(item) {
      item.status = 0
    },
    proposedDate() {
      if (this.finishedConsults) {
        switch (this.finishedConsults) {
          case 'INITIAL_VALORATION':
            this.proposed_date = this.elementData.follow_date_1
              ? this.elementData.follow_date_1
              : ''
            break
          case 'FIRST_FOLLOW':
            this.proposed_date = this.elementData.follow_date_2
              ? this.elementData.follow_date_2
              : ''
            break
          case 'SECOND_FOLLOW':
            this.proposed_date = this.elementData.follow_date_3
              ? this.elementData.follow_date_3
              : ''
            break
          case 'THIRD_FOLLOW':
            this.proposed_date = this.elementData.follow_date_4
              ? this.elementData.follow_date_4
              : ''
            break
          default:
            this.proposed_date = ''
            break
        }
      }
    },

    addMedicalLaboratory() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        added_by: this.userData.fullName,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'LABORATORIES'
        ),
        selected_services: [],
        services: [],
        proposed_date: _self.proposed_date,
      }
      _self.$root.$emit('bv::show::modal', 'modal-clinical-laboratory')
    },
    addVaccine() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        added_by: this.userData.fullName,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'VACCINATION'
        ),
        services: [],
        accident_date: _self.elementData.accident_date,
      }

      _self.$root.$emit('bv::show::modal', 'modal-vaccines')
    },
    addMedicalConsult() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'FOLLOWUPS'
        ),
        service: {},
        proposed_date: _self.proposed_date,
      }
      this.$root.$emit('bv::show::modal', 'modal-medical-consult')
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
