<template>
  <div v-if="elementData">
    <b-form
      overlay
      @submit.prevent="validateForm">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(RESOURCES_ELEMENT_NAME + '.section.order_gestion')
            }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75" />
              <span class="font-weight-bold info_title">{{
                $t(RESOURCES_ELEMENT_NAME + '.object.follow_type')
              }}</span>
            </b-col>
            <b-col cols="4">
              <div
                v-if="loading"
                class="loading-indicator">
                Cargando...
              </div>
              <v-select
                v-else
                v-model="follow_type_id"
                :options="followTypeList"
                label="value"
                value="id"
                :reduce="(option) => option.id"
                :disabled="loading"
                @input="onChangeType" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <seven-medical-orders-card
                v-if="selectedElementData.orders"
                ref="page8"
                :element-data="selectedElementData"
                :destination-orders-list="destinationOrdersList"
                :clinical-laboratory-city-list="clinicalLaboratoryCityList"
                :clinical-laboratory-department-list="
                  clinicalLaboratoryDepartmentList
                "
                :clinical-headquarter-list="clinicalHeadquarterList"
                :clinical-laboratory-list="clinicalLaboratoryList"
                :clinical-laboratory-services-list="
                  clinicalLaboratoryServicesList
                "
                :vaccine-city-list="vaccineCityList"
                :vaccine-department-list="vaccineDepartmentList"
                :vaccine-headquarter-list="vaccineHeadquarterList"
                :vaccine-laboratory-list="vaccineLaboratoryList"
                :vaccine-services-list="vaccineServicesList"
                :service-type-list="serviceTypeList"
                :case-tracking-type="caseTrackingType"
                :medical-consult-services-list="medicalConsultServicesList"
                :case-type="case_type"
                :boolean-list="booleanList"
                :disabled="disabled"
                :finished-consults="finishedConsults"
                :follow-type="followType"
                :required-headquarter="requiredHeadquarter"
                :required-vaccine-headquarter="requiredVaccineHeadquarter" />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)">
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="submitting">
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BForm,
  BCardBody,
  BCardHeader,
} from 'bootstrap-vue'
import router from '@/router'
import vSelect from 'vue-select'
import store from '@/store'
import { mapState } from 'vuex'
import { GoodWizard } from 'vue-good-wizard'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SevenMedicalOrdersCard from './SevenMedicalOrdersCard.vue'
import elementStoreModule from '../storeModule'
// import { Balloon } from 'vue-balloon'
import '@core/scss/vue/libs/vue-select.scss'
import company from '@/router/routes/companies/company'

export default {
  components: {
    BCardHeader,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    vSelect,
    // Balloon,
    'vue-good-wizard': GoodWizard,
    SevenMedicalOrdersCard,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      elementData: {},
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      followTypeList: [],
      destinationOrdersList: [],
      clinicalLaboratoryCityList: [],
      clinicalLaboratoryDepartmentList: [],
      clinicalHeadquarterList: [],
      clinicalLaboratoryList: [],
      clinicalLaboratoryServicesList: [],
      vaccineCityList: [],
      vaccineDepartmentList: [],
      vaccineHeadquarterList: [],
      vaccineLaboratoryList: [],
      vaccineServicesList: [],
      caseTrackingType: [],
      medicalConsultServicesList: [],
      serviceTypeList: [],
      booleanList: [],
      finishedConsults: null,
      meet_uuid: undefined,
      uuid: undefined,
      selectedElementData: {},
      follow_type_id: undefined,
      case_type: '',
      submitting: false,
      followType: '',
      loading: true,
      requiredHeadquarter: false,
      requiredVaccineHeadquarter: false,
    }
  },
  computed: {
    ...mapState('dataList', ['getDataList']),
  },
  beforeDestroy() {
    this.$root.$off('fetch-clinical-cities')
    this.$root.$off('fetch-vaccine-cities')
    this.$root.$off('fetch-clinical-laboratory-headquarters')
    this.$root.$off('filter-clinical-headquarters-by-city')
    this.$root.$off('filter-clinical-headquarters-by-department')
    this.$root.$off('fetch-vaccine-headquarters')
    this.$root.$off('filter-vaccine-headquarters-by-city')
    this.$root.$off('filter-vaccine-headquarters-by-department')
    this.$root.$off('change-type')
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'consult'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-med-ord-loc-${this.RESOURCES_ELEMENT_NAME}`
    const _self = this
    this.steps = this.steps_hepa

    await this.$store
      .dispatch('dataList/fetch_lists', { _country: 'Colombia' })
      .then()
      .catch((error) => {
        console.error('Error fetching data:', error)
      })

    const dataStore_Select = await this.$store.getters['dataList/getDataList']({
      listState: [
        'certificationType',
        'citiesList',
        'statesList',
        'caseTrackingType',
      ],
      messageDefault: 'SELECT_ONE',
    })

    const dataStore = await this.$store.getters['dataList/getDataList']({
      listState: [
        'services',
        'company',
        'headquarters',
        'destinationOrder',
        'serviceType',
        'boolean',
      ],
    })

    this.$root.$on('fetch-clinical-cities', (department_id) => {
      _self.clinicalLaboratoryCityList = dataStore_Select.citiesList.filter(
        ({ state }) => !state || (state && state.id === department_id)
      )
    })
    this.$root.$on('fetch-vaccine-cities', (department_id) => {
      _self.vaccineCityList = dataStore_Select.citiesList.filter(
        ({ state }) => !state || (state && state.id === department_id)
      )
    })
    this.$root.$on('fetch-clinical-laboratory-headquarters', (company_id) => {
      _self.clinicalHeadquarterList = company_id
        ? dataStore.headquarters.filter((hq) => hq.company_id === company_id)
        : []
      _self.requiredHeadquarter = _self.clinicalHeadquarterList.length !== 0
    })
    this.$root.$on(
      'filter-clinical-headquarters-by-department',
      (company_id, department_id) => {
        _self.clinicalHeadquarterList = company_id
          ? dataStore.headquarters.filter(
              (hq) =>
                hq.company_id === company_id &&
                hq.department_id === department_id
            )
          : []
      }
    )
    this.$root.$on(
      'filter-clinical-headquarters-by-city',
      async (company_id, city_id) => {
        company_id
          ? dataStore.headquarters.filter(
              (hq) => hq.company_id == company_id && hq.city_id === city_id
            )
          : []
      }
    )
    this.$root.$on('fetch-vaccine-headquarters', async (company_id) => {
      _self.vaccineHeadquarterList = company_id
        ? dataStore.headquarters.filter((hq) => hq.company_id === company_id)
        : []
      _self.requiredVaccineHeadquarter =
        _self.vaccineHeadquarterList.length !== 0
    })
    this.$root.$on(
      'filter-vaccine-headquarters-by-department',
      async (company_id, department_id) => {
        _self.vaccineHeadquarterList = company_id
          ? dataStore.headquarters.filter(
              (hq) =>
                hq.company_id === company_id &&
                hq.department_id === department_id
            )
          : []
      }
    )
    this.$root.$on(
      'filter-vaccine-headquarters-by-city',
      async (company_id, city_id) => {
        _self.vaccineHeadquarterList = company_id
          ? dataStore.headquarters.filter(
              (hq) => hq.company_id === company_id && hq.city_id === city_id
            )
          : []
      }
    )
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        elementStoreModule
      )
    _self.caseTrackingType = dataStore_Select.caseTrackingType
    _self.vaccineDepartmentList = dataStore_Select.statesList
    _self.clinicalLaboratoryDepartmentList = dataStore_Select.statesList
    _self.serviceTypeList = dataStore.serviceType
    _self.destinationOrdersList = dataStore.destinationOrder

    _self.booleanList = dataStore.boolean

    const internalCompanies = dataStore.company
    const internalServices = dataStore.services.map((services) => ({
      ...services,
      value: `${services.cups} ${services.description}`,
    }))
    // Filter clinical laboratory by supplier_type_value
    _self.clinicalLaboratoryList = internalCompanies.filter(
      (comp) => comp.supplier_type_key === 'LABORATORY'
    )

    // Filter vaccine laboratory by supplier_type_value
    _self.vaccineLaboratoryList = internalCompanies.filter(
      (comp) => comp.supplier_type_key === 'VACCINE'
    )
    // Filter clinical laboratory service by service_type_value
    _self.clinicalLaboratoryServicesList = internalServices.filter(
      (serv) => serv.service_type.key === 'LABORATORIES'
    )

    _self.medicalConsultServicesList = internalServices.filter(
      (serv) => serv.service_type.key === 'FOLLOWUPS'
    )
    // Filter vaccine service by service_type_value
    _self.vaccineServicesList = internalServices.filter(
      (serv) => serv.service_type.key === 'VACCINATION'
    )

    await this.fetchElement()

    _self.loading = false
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    router() {
      return router
    },
    onChangeType() {
      this.followType = this.followTypeList.find(
        (element) => element.id === this.follow_type_id
      ).key
      const follow_type_on_step =
        this.followType === 'INITIAL_VALORATION'
          ? 'FIRST_DATE'
          : this.followType
      for (const step of this.elementData.process.steps) {
        if (step.step_type.key.includes(follow_type_on_step)) {
          this.selectedElementData = step.case_date.meet
          this.meet_uuid = step.case_date.meet_uuid
        }
      }
    },
    async fetchElement() {
      this.newElement = false

      const new_types = []
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, {
          id: router.currentRoute.params.case_id,
        })
        .then(async (response) => {
          this.elementData = response.data

          this.elementData.process.steps
            .filter(
              ({ step_status }) =>
                step_status && step_status.key.includes('FINISHED')
            )
            .filter(
              ({ step_type }) =>
                !['ADDITIONAL', 'EVOLUTION'].includes(step_type.key)
            )
            .map(async (step) => {
              const caseDate = await this.fetchCaseDate(step.id)
              step.case_date = caseDate
            })
          this.followTypeList = this.elementData?.process?.steps
            ?.sort((a, b) => a.id - b.id)
            .filter(({ step_status }) => step_status.key.includes('FINISHED'))
            .filter(
              ({ step_type }) =>
                !['ADDITIONAL', 'EVOLUTION'].includes(step_type.key)
            )
            .map(({ step_type }) => {
              return step_type.key === 'FIRST_DATE'
                ? this.caseTrackingType.filter(
                    ({ key }) => key === 'INITIAL_VALORATION'
                  )[0]
                : this.caseTrackingType.filter(
                    ({ key }) => key === step_type.key
                  )[0]
            })
          this.finishedConsults =
            this.followTypeList[this.followTypeList.length - 1].key
        })
        .catch((error) => {
          console.error('error on fetchElement', error)
          if (error.response.status === 404) {
            this.elementData = undefined
          }
        })
    },
    async fetchCaseDate(step_id) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_case_date`, {
          step_id,
        })
        .then(async (response) => {
          const { data } = response
          data.meet_uuid = data.meet.uuid
          data.meet = await this.fetchMeetElement(data.meet.uuid)
          return data
        })
        .catch((error) => {
          console.error('error on fetchCaseDate', error)
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    async fetchMeetElement(meet_uuid) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_meet_element`, {
          meet_uuid,
        })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.error('error on fetchMeetElement', error)
          if (error.response.status === 404) {
            return {}
          }
        })
    },
    async validateForm() {
      const _self = this
      const success = await _self.$refs.page8.$refs.elementForm.validate()
      if (success) {
        this.elementData.is_last_page = true
        this.selectedElementData.is_last_page = true
        this.saveForm()
      }
    },
    async save() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/save_medical_orders`
      const _self = this
      _self.selectedElementData.change = false // ! NO DELETE
      return store
        .dispatch(method, {
          elementData: _self.selectedElementData,
          meet_uuid: this.meet_uuid,
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.failure`
              ),
            },
          })
          return false
        })
    },
    async generate_document_medical_orders() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/generate_final_document`
      const _self = this
      return store
        .dispatch(method, {
          meet_uuid: _self.uuid,
          template_type: 'MEDICAL_ORDER',
          notification_destination: 1,
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.failure`
              ),
            },
          })
          return false
        })
    },
    async saveForm() {
      this.process_additional_data()
      const _self = this
      if (await _self.save()) {
        if (_self.elementData.has_medical_orders) {
          await _self.generate_document_medical_orders()
        }
        _self.$router.replace({ name: 'apps-case-following-list' })
      }
    },
    process_additional_data() {
      if (this.elementData.orders) {
        this.elementData.has_medical_orders = !!this.elementData.orders.find(
          (element) => element.service_type.key == 'MEDICINES'
        )
      }
      if (this.elementData.orders) {
        this.elementData.has_followups_orders = !!this.elementData.orders.find(
          (element) => element.service_type.key == 'FOLLOWUPS'
        )
      }
    },
  },
}
</script>
<style>
.float {
  position: fixed;
  top: 15px;
  right: 5px;
  background-color: grey;
  padding: 10px;
  border-radius: 5px;
}

.custom-select:disabled {
  color: #42474c;
  opacity: 1;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: bolder;
}
</style>
