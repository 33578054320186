var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"vaccine_services"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.vaccine_services')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.vaccine_services')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.vaccineServicesList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onServiceChange},model:{value:(_vm.vaccineData.service_id),callback:function ($$v) {_vm.$set(_vm.vaccineData, "service_id", $$v)},expression:"vaccineData.service_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vaccine_scheme"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.vaccine_scheme')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.vaccine_scheme')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.vaccineSchemesList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onSchemeChange},model:{value:(_vm.vaccineData.vaccine_scheme_id),callback:function ($$v) {_vm.$set(_vm.vaccineData, "vaccine_scheme_id", $$v)},expression:"vaccineData.vaccine_scheme_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('date-picker',{ref:"first_dosage_id",attrs:{"element-label":_vm.$t(
                _vm.consultResourcesElementName + '.object.vaccine_first_dossage'
              ),"element-required":false,"root-element-data":_vm.vaccineData,"element-field":"first_dosage"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('date-picker',{attrs:{"element-label":_vm.$t(
                _vm.consultResourcesElementName + '.object.vaccine_second_dosage'
              ),"element-required":false,"root-element-data":_vm.vaccineData,"element-field":"second_dosage"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('date-picker',{attrs:{"element-label":_vm.$t(
                _vm.consultResourcesElementName + '.object.vaccine_third_dossage'
              ),"element-required":false,"root-element-data":_vm.vaccineData,"element-field":"third_dosage"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('date-picker',{attrs:{"element-label":_vm.$t(
                _vm.consultResourcesElementName + '.object.vaccine_fourth_dossage'
              ),"element-required":false,"root-element-data":_vm.vaccineData,"element-field":"fourth_dosage"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('div',{staticClass:"hidden-div",attrs:{"id":"vaccine_antibodies_text"}},[_vm._v(" 906223 - Hepatitis B ANTICUERPOS S [ANTI-HBs] SEMIAUTOMATIZADO O AUTOMATIZADO ")])]),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('date-picker',{attrs:{"element-label":_vm.$t(
                _vm.consultResourcesElementName +
                  '.object.vaccine_antibody_dossage'
              ),"element-required":false,"root-element-data":_vm.vaccineData,"element-field":"antibody_dosage"}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }