<template>
  <b-form @submit.prevent="save">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.clinical_laboratory')
            }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="true"
                element-field="request_destination_id"
                :root-element-data="orderData"
                :element-label="
                  $t(
                    consultResourcesElementName + '.object.request_destination'
                  )
                "
                :element-selection-list="internalDestinationList"
                :inline="true"
                :exec="update_request_destination" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              md="4">
              <b-form-group label-for="element-status">
                <b-form-checkbox
                  v-model="orderData.requires_freezer"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0" />
                <span class="font-weight-bold">{{
                  $t(consultResourcesElementName + '.object.requires_freezer')
                }}</span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col cols="12">
              <date-picker
                :element-required="false"
                element-field="proposed_date"
                :root-element-data="orderData"
                :element-label="
                  $t(consultResourcesElementName + '.object.proposed_date')
                " />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="city">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(
                    consultResourcesElementName +
                      '.object.clinical_laboratory_lab'
                  )
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.clinical_laboratory_lab'
                    )
                  ">
                  <v-select
                    v-model="orderData.clinical_laboratory_lab_id"
                    :options="localClinicalLaboratoryList"
                    label="name"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onLaboratoryChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              md="4">
              <b-form-group label-for="clinical_department">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(
                    consultResourcesElementName + '.object.clinical_department'
                  )
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.clinical_department'
                    )
                  ">
                  <v-select
                    v-model="orderData.clinical_department_id"
                    :options="clinicalLaboratoryDepartmentList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onDepartmentChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4">
              <b-form-group label-for="city">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(consultResourcesElementName + '.object.clinical_city')
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(consultResourcesElementName + '.object.clinical_city')
                  ">
                  <v-select
                    v-model="orderData.clinical_city_id"
                    :options="clinicalLaboratoryCityList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onCityChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4">
              <b-form-group label-for="clinical_laboratory_headquarter">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(
                    consultResourcesElementName +
                      '.object.clinical_laboratory_headquarter'
                  )
                }}</span>
                <span
                  v-if="requiredHeadquarter"
                  class="text-danger"
                  >*</span
                >
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.clinical_laboratory_headquarter'
                    )
                  "
                  :rules="{
                    required: requiredHeadquarter,
                  }">
                  <v-select
                    v-model="orderData.clinical_laboratory_headquarter_id"
                    :options="clinicalHeadquarterList"
                    label="name"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onHeadquarterChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              xl="12">
              <b-form-group label-for="clinical_laboratory_headquarter_address">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(
                    consultResourcesElementName +
                      '.object.clinical_laboratory_headquarter_address'
                  )
                }}</span>

                <b-form-input
                  id="clinical_laboratory_headquarter_address"
                  v-model="address"
                  :placeholder="
                    $t(
                      consultResourcesElementName +
                        '.object.clinical_laboratory_headquarter_address'
                    )
                  "
                  :disabled="true" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="9"
              md="9">
              <filtered-multi-select
                v-if="
                  clinicalLaboratoryServicesList &&
                  clinicalLaboratoryServicesList.length
                "
                :root-element-data="orderData"
                :element-label="
                  $t(consultResourcesElementName + '.object.clinical_services')
                "
                element-field="selected_services"
                :available-list="clinicalLaboratoryServicesList" />
            </b-col>
            <b-col
              cols="3"
              md="3">
              <b-button
                id="add-button"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="primary"
                @click="add_services()">
                {{ $t('actions.add_service') }}
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-table
              class="position-relative"
              :items="orderData.services"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              :empty-text="
                $t(consultResourcesElementName + '.search.not_found')
              ">
              <!-- Column: actions -->
              <template #cell(actions)="data">
                <b-col>
                  <b-button
                    :id="get_delete_button_id(data.item.id)"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    variant="primary"
                    @click="delete_service(data.item.id)">
                    <feather-icon icon="DeleteIcon" />
                  </b-button>

                  <b-tooltip
                    :target="get_delete_button_id(data.item.id)"
                    triggers="hover">
                    <span class="align-middle ml-50">{{
                      $t('actions.delete')
                    }}</span>
                  </b-tooltip>
                </b-col>
              </template>
            </b-table>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="service_observations">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(
                    consultResourcesElementName + '.object.service_observations'
                  )
                }}</span>

                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.service_observations'
                    )
                  ">
                  <b-form-textarea
                    id="observations"
                    v-model="orderData.observations"
                    maxlength="250"
                    max="250" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-row>
        <b-button
          id="cancel-button"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          variant="primary"
          @click="hide_modal()">
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid">
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BTooltip,
  BTable,
  BFormTextarea,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import FilteredMultiSelect from '@/views/core/FilteredMultiSelect.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import DatePicker from '@/views/core/DatePicker.vue'

export default {
  components: {
    DatePicker,
    BTooltip,
    BTable,
    FilteredMultiSelect,
    BFormTextarea,
    RadioSelectPicker,
    BFormCheckbox,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    destinationOrdersList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryDepartmentList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryCityList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    clinicalHeadquarterList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryServicesList: {
      type: Array,
      required: true,
    },
    requiredHeadquarter: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      address: '',
      tableColumns: [],
      localClinicalLaboratoryList: [],
      internalDestinationList: [],
    }
  },
  watch: {},
  mounted() {
    this.localClinicalLaboratoryList = [...this.clinicalLaboratoryList]
    this.$root.$emit('fetch-clinical-cities', this.orderData.department_id)
    this.$root.$emit(
      'fetch-clinical-laboratory-headquarters',
      this.orderData.clinical_laboratory_lab_id
    )

    this.tableColumns = [
      {
        key: 'service.cups',
        label: this.$t(
          `${this.consultResourcesElementName}.object.service_cups`
        ),
        sortable: true,
      },
      {
        key: 'service.description',
        label: this.$t(
          `${this.consultResourcesElementName}.object.service_description`
        ),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.consultResourcesElementName}.object.actions`),
      },
    ]
    this.internalDestinationList = this.destinationOrdersList.filter(
      (element) => element.key === 'PATIENT' || element.key === 'SOURCE'
    )
  },
  methods: {
    get_delete_button_id(key) {
      return `delete_service_button${key}`
    },
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-clinical-laboratory')
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          _self.$root.$emit('save-order', _self.orderData)
          _self.$root.$emit('bv::hide::modal', 'modal-clinical-laboratory')
        }
      })
    },
    update_request_destination() {
      this.orderData.request_destination = this.destinationOrdersList.find(
        (element) => element.id == this.orderData.request_destination_id
      )
    },
    onDepartmentChange() {
      this.orderData.city_id = null
      this.$root.$emit(
        'fetch-clinical-cities',
        this.orderData.clinical_department_id
      )
      this.$root.$emit(
        'filter-clinical-headquarters-by-department',
        this.orderData.clinical_laboratory_lab_id,
        this.orderData.clinical_department_id
      )
    },
    onCityChange() {
      this.orderData.clinical_laboratory_headquarter_id = null
      this.$root.$emit(
        'filter-clinical-headquarters-by-city',
        this.orderData.clinical_laboratory_lab_id,
        this.orderData.clinical_city_id
      )
    },
    onLaboratoryChange() {
      this.orderData.clinical_department_id = null
      this.orderData.clinical_city_id = null
      this.orderData.clinical_laboratory_headquarter_id = null
      this.$root.$emit(
        'fetch-clinical-laboratory-headquarters',
        this.orderData.clinical_laboratory_lab_id
      )
      const laboratory = this.clinicalLaboratoryList.find(
        (element) => element.id == this.orderData.clinical_laboratory_lab_id
      )
      this.address = laboratory ? laboratory.address : ''
    },
    onHeadquarterChange() {
      this.address = this.orderData.clinical_laboratory_headquarter_id
        ? this.clinicalHeadquarterList.find(
            (element) =>
              element.id == this.orderData.clinical_laboratory_headquarter_id
          ).address
        : this.address
    },
    add_services() {
      for (const service of this.orderData.selected_services) {
        const serv = this.clinicalLaboratoryServicesList.find(
          (element) => element.id == service
        )
        if (!this.orderData.services.find((element) => element.id == service)) {
          this.orderData.services.push({ id: serv.id, service: serv })
        }
      }
    },
    delete_service(id) {
      this.orderData.services.splice(
        this.orderData.services.findIndex((element) => element.id == id),
        1
      )
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find((option) => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
